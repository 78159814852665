<template>
  <div :style="slidePosition" class="h-100 z-2" style="width:100%;">
    <inline-svg src="images/horizontal-blue-line.svg" class="position-absolute" width="80%" aria-label="Blue line"
      style="top:80%;left:20%;"></inline-svg>
  </div>
</template>

<script>
import { slidePositionParallax } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionParallax(this.globalUnits, this.slideIndex)
    }
  },
  mounted() { },
  methods: {}
}
</script>
<style lang="scss" scoped>
$star-color-A: white;
$star-color-B: rgba(255, 255, 255, 0.1);

#sky {
  transition: all 3s ease;
  transform: translateZ(0);
}
</style>
